import {
  CardProductsBenefits,
  CardProductsLinks,
  CardProductsRelated,
  CardProductsSummary,
  Wrapper,
  PageLoading,
  CardProductsPackaging,
} from "@royalcanin-be-partner-portal/ui-kit";
import React, { useEffect } from "react";
import { Row } from "react-styled-flexboxgrid";
import { Layout } from "../../components/Layout";
import { useProduct } from "../../lib/hooks/useProducts";
import { parse } from "query-string";
import { RouteComponentProps } from "@reach/router";
import { CheckPermission } from "../../components/CheckPermission";
import { withPage } from "../../lib/withPage";
import { useUserRole } from "../../lib/hooks/useUserRole";
import styled from "styled-components";
import { mediaQuery } from "react-styled-mediaquery";
import { navigate } from "gatsby";

type ProductSection =
  | "summary"
  | "benefits"
  | "links"
  | "packagings"
  | "relatedProducts";

const ProductsDetail = ({ location }: RouteComponentProps) => {
  const { reference } = parse(location?.search || "");

  useEffect(() => {
    if (!reference) {
      navigate("/products");
    }
  }, [reference]);

  const { loading: roleLoading, data } = useUserRole();
  const { item: product, loading: productLoading } = useProduct(
    reference as string | null | undefined,
  );

  const sectionsEnabled = data?.roleDefinition.sectionProductEnabled || [];
  const isSectionEnabled = (name: ProductSection) => {
    if (!sectionsEnabled.length) {
      return true;
    }
    return sectionsEnabled.includes(name);
  };
  const hasProductLinks = !!(
    product?.videos.length ||
    product?.productSheets.length ||
    product?.images.length
  );

  const loading = roleLoading || productLoading || !reference;

  return (
    <Layout>
      <CheckPermission permissions={"products"} redirect>
        <Wrapper>
          {loading && <PageLoading />}
          <Row>
            {isSectionEnabled("summary") && (
              <CardContainer>
                <CardProductsSummary loading={loading} product={product} />
              </CardContainer>
            )}
            {/* <ColFlex xs={12} sm={12} md={6} lg={4}>
              <CardProductsCriteria
                loading={loading}
                product={product}
              />
              </ColFlex> */}
            {isSectionEnabled("benefits") && (
              <CardContainer $flex={{ lg: 2, md: 1 }}>
                <CardProductsBenefits loading={loading} product={product} />
              </CardContainer>
            )}
          </Row>
          <RowProducts>
            {loading ||
              (hasProductLinks && isSectionEnabled("links") && (
                <CardContainer>
                  <CardProductsLinks loading={loading} product={product} />
                </CardContainer>
              ))}
            {isSectionEnabled("packagings") && (
              <CardContainer>
                <CardProductsPackaging loading={loading} product={product} />
              </CardContainer>
            )}
          </RowProducts>
          {!!(loading || product?.relatedCollections.length) &&
            isSectionEnabled("relatedProducts") && (
              <RowProducts>
                <CardContainer>
                  <CardProductsRelated loading={loading} product={product} />
                </CardContainer>
              </RowProducts>
            )}
        </Wrapper>
      </CheckPermission>
    </Layout>
  );
};
export default withPage(ProductsDetail);

export const RowProducts = styled(Row)`
  margin-top: 30px;

  ${mediaQuery("<=", "960px")`
    margin-top: 15px;
  `}
`;

const CardContainer = styled.div<{
  $flex?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
  };
}>`
  display: flex;
  flex: 1;
  padding: 0 15px;

  ${(p) =>
    p.$flex?.lg &&
    mediaQuery(">", "960px")`
    flex: ${p.$flex.lg};
  `}
  ${(p) =>
    p.$flex?.md &&
    mediaQuery("<=", "960px")`
    flex: ${p.$flex.md};
  `}
  ${(p) =>
    p.$flex?.sm &&
    mediaQuery("<=", "768px")`
    flex: ${p.$flex.sm};
  `}
  ${(p) =>
    p.$flex?.xs &&
    mediaQuery("<=", "412px")`
    flex: ${p.$flex.xs};
  `}
`;
